import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { useMemo, useState } from "react";
import { Controller, useForm, type SubmitHandler } from "react-hook-form";
import { z } from "zod";
import { Button } from "../../../components/layout/Button/Button";
import { ButtonStyleVariant } from "../../../components/layout/Button/types";
import Checkbox from "./components/Checkbox/Checkbox";
import Input from "./components/Input/Input";
import "./style.scss";

type FormFields = {
  email: string;
  terms: boolean;
};

const Contact = () => {
  const [placeholderMessage, setPlaceholderMessage] = useState("Email");
  const [isAnimating, setIsAnimating] = useState(false);

  const schema = useMemo(
    () =>
      z.object({
        email: z.string().email(),
        terms: z.boolean().refine((v) => v, {
          message: "Terms needs to be accepted.",
        }),
      }),
    [],
  );

  const {
    handleSubmit,
    control,
    formState: { isValidating, isValid, isSubmitting },
    reset,
  } = useForm<FormFields>({
    defaultValues: {
      email: "",
      terms: false,
    },
    resolver: zodResolver(schema),
    mode: "all",
  });

  const animatePlaceholderMessage = (message: string) => {
    setPlaceholderMessage(message);
    setIsAnimating(true);
    setTimeout(() => setIsAnimating(false), 750);
  };

  const handleValidSubmit: SubmitHandler<FormFields> = async (vals) => {
    try {
      const res = await axios.post("/api/v1/email/collect-email", {
        email: vals.email.trim(),
      });
      if (res.status === 200) {
        animatePlaceholderMessage(
          "Thank you for subscribing! We've sent a confirmation email to your inbox.",
        );
        reset();
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <form id="contact-form" onSubmit={handleSubmit(handleValidSubmit)}>
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <Input
            id="field-email"
            placeholder={placeholderMessage}
            {...field}
            autoComplete="off"
            className={isAnimating ? "animated-placeholder" : ""}
          />
        )}
      />
      <div className="row">
        <Controller
          name="terms"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Checkbox
              value={value}
              onChange={onChange}
              buttonProps={{ "aria-label": "Terms and conditions checkbox" }}
            />
          )}
        />
        <p>
          I acknowledge and agree to Acquinox{" "}
          <a
            href="/legal#terms-and-conditions"
            target="_blank"
            aria-label="Terms and conditions link"
          >
            Terms and Conditions
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            href="/legal#privacy"
            aria-label="Privacy/Data Protection Policy"
          >
            Privacy/Data Protection Policy
          </a>
        </p>
        <Button
          text="Send"
          styleVariant={ButtonStyleVariant.INVERTED}
          type="submit"
          disabled={!isValid || isValidating || isSubmitting}
          aria-label="Contact form submit."
        />
      </div>
    </form>
  );
};

export default Contact;
